<template lang="pug">
.settings-page
  .wrapper
    a.settings-page__breadcrumb(v-if="isGoBack" @click="goBack")
      ui-icon(:icon="UiIconNames.Chevron_Left" :size="18")
      | Вернуться назад
    router-link.settings-page__breadcrumb(v-else :to="{ name: Tabs.Settings.Main }")
      ui-icon(:icon="UiIconNames.Chevron_Left" :size="18")
      | Вернуться к настройкам
  .settings-page__title
    h1 {{ title }}
    .header-actions(v-if="$slots.headerButtons")
      slot(name="headerButtons")
  .settings-page__container
    slot
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Tabs } from "@/router/tabs";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SettingsPage",
  components: {
    UiIcon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    isGoBack: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  },
  setup() {
    return {
      Tabs,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";

.settings-page {
  @include adaptive-view;
}

.wrapper {
  display: flex;
  width: fit-content;
}

.settings-page__breadcrumb {
  margin-top: 24px;
  color: var(--main-color-breadcrumb);
  font-size: 14px;
  text-decoration: none;
  display: flex;
  line-height: 18px;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: #409eff;
  }
}

.settings-page__title {
  display: flex;
  flex-flow: row wrap;
  padding: 16px 0 32px;
  gap: 8px;

  h1 {
    color: var(--main-color-black);
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-transform: uppercase;
    flex: 1 1 0;
  }

  .header-actions {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    margin-left: auto;
    height: fit-content;
    justify-content: end;
  }
}

.settings-page__container {
  display: flex;
  flex-flow: column;
  gap: 32px;
}
</style>
